import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SEO from "../SEO";
import Header from "../Header";
import setWindowHeightProperty from "../../utils/setWindowHeightProperty";
import Footer from "../Footer/Footer";

const Layout = ({
  children,
  seoTitle,
  seoDescription,
  location,
  hasLightTheme,
  hasMenuIcon
}) => {
  useEffect(() => {
    setWindowHeightProperty();
  }, []);

  return (
    <div className="font-sans font-light">
      <SEO title={seoTitle} description={seoDescription} location={location} />
      <Header
        lightTheme={hasLightTheme}
        menuIcon={hasMenuIcon}
        location={location}
      />
      {children}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
